export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Team</h2>
          <p>Over three decades of professional experience in Interior design, Associate Member of Indian Institute of Interior Designers, and ex Design jury at Architectural college Pune.</p>
          {/* <p>Obtained initial training under a German Interior architect in Delhi and then worked with leading Architects in the industry in Delhi, Pune, Mumbai, Chennai and Muscat.</p> */}
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-12 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        {/* <div className="col-md-8 col-md-offset-2 section-title">
          <p>As a Senior Associate – Design at Chaney Architects at Pune in the last decade and has independently managed following projects:</p>
          <ul>
            <li>Ruby Hall Cancer Centre, Pune.</li>
            <li>Asian Eye Institute, Pune – 360-degree eye care hospital</li>
            <li>Ruby Hall Clinic, Wanowrie , Pune - (100 Bed Multi speciality Hospital), won IIID Award for Interior Design</li>
            <li>Ruby Hall Clinic, Hinjewadi, Pune - (50-Bed Satellite Hospital)</li>
            <li>
              Ruby Hall Clinic, Sassoon road, Pune – (Super Speciality Hospital), OPD building with specialised facilities like Linac bunkers, nuclear medicine, world class Neurology, Urology canters
              and general OPD’s etc , Pune.
            </li>
            <li>Cureall Cancer Hospital, Nagpur – (80 – Bed Cancer hospital)</li>
            <li>Renovation and retrofitting of specialized departments in running hospitals, Pune</li>
            <li>Many high-end residential apartment interiors</li>
            <li>Bungalow, farmhouse interiors</li>
            <li>Clover Hills Plaza, Pune - Mall and office interiors</li>
          </ul>
        </div> */}
        </div>
      </div>
    </div>
  );
};
