export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.title}-${i}`} className="col-sm-6 col-md-4 col-lg-4">
                    <div className="portfolio-item">
                      <div className="hover-bg">
                        <a href={`img/portfolio/${d.small}`} title={d.title} data-lightbox-gallery={d.lightbox}>
                          <div className="hover-text">
                            <h4>Indesign</h4>
                          </div>
                          <img src={`img/portfolio/${d.large}`} className="img-responsive" alt={d.title} copyright="chaneyarchitects" />
                        </a>
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    </div>
  );
};
